import React from "react";

import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import {
  FaCheckCircle,
  FaHandPointRight,
  FaLaptop,
  FaLockOpen,
  FaServer,
  FaSoundcloud,
} from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import { FaWaveSquare } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { FaSlideshare } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { FaCommentAlt } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FaArrowRight } from "react-icons/fa";

import { FaStarOfLife } from "react-icons/fa";
import { FaFileCode } from "react-icons/fa";
import { FaSearchengin } from "react-icons/fa";
import { FaSolarPanel } from "react-icons/fa";
import { FaScrewdriver } from "react-icons/fa";

import Container from "react-bootstrap/Container";
import worka from "../../assets/images/worka.png";
import workb from "../../assets/images/workb.png";
import workc from "../../assets/images/workc.png";
import workd from "../../assets/images/workd.png";
import worke from "../../assets/images/worke.png";
import workf from "../../assets/images/workf.png";
import indu1 from "../../assets/images/indu1.svg";
import indu2 from "../../assets/images/indu2.svg";
import indu3 from "../../assets/images/indu3.svg";
import indu4 from "../../assets/images/indu4.svg";
import indu5 from "../../assets/images/indu5.svg";
import indu6 from "../../assets/images/indu6.svg";
import softwareqaservices from "../../assets/images/software-qa-services.svg";
import serviceall from "../../assets/images/serviceall.svg";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ContactForm from "../../components/ContactForm";
const SoftwareAndWebDevelopment = () => {
  const options2 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>Software and Web Development</strong>
                </h1>
                <p className="text-white p20 mb-4">
                  Automation Services Ltd specializes in high-quality software
                  and web development services tailored to meet diverse business
                  needs in Bangladesh and beyond. Our skilled developers excel
                  in creating scalable, secure, and efficient web applications
                  using cutting-edge technologies like ReactJS, NextJS, NodeJS,
                  and Java Spring Boot. From dynamic websites to custom software
                  solutions, ASL delivers robust digital products that enhance
                  user experience, boost productivity, and drive business
                  growth. Partner with ASL to transform your ideas into
                  innovative, result-driven solutions.
                </p>
              </div>
              <Row>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>In-Depth Consultation</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Continuous Support</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Secure Deployment</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>User-Centric Testing</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Tailored Solution Design</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label> On-Time Project Delivery </label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>

      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="mt-4 mb-4" data-aos="fade-left">
              <h2>Our Software & Web Development Services</h2>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaStarOfLife />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Custom Software Development</h5>
                    <p className="mb-0">
                      Tailored software solutions designed to meet specific
                      business needs, enhancing operational efficiency and
                      addressing unique challenges. Custom software allows
                      organizations to develop applications that fit their
                      processes perfectly, providing a competitive edge.
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Web Application Development</h5>
                    <p className="mb-0">
                      Creating responsive and user-friendly web applications
                      that function seamlessly across various devices and
                      browsers. This involves utilizing modern frameworks and
                      technologies to build robust applications, enhancing user
                      engagement and overall satisfaction.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSlideshare />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className="img-width" src={serviceall} alt="serviceall" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaLaptop />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">E-commerce Solutions</h5>
                    <p className="mb-0 pb-3">
                      Development of comprehensive e-commerce platforms that
                      enable businesses to sell products and services online
                      effectively. This includes secure payment processing,
                      user-friendly interfaces, and inventory management
                      systems, optimizing the online shopping experience for
                      customers.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Content Management Systems (CMS)</h5>
                    <p className="mb-0">
                      Building user-friendly CMS platforms that allow businesses
                      to easily manage and update their website content without
                      needing extensive technical knowledge. This empowers
                      organizations to maintain control over their digital
                      presence and keep their content up-to-date.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaFileCode />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaServer />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">API Development and Integration</h5>
                    <p className="mb-0 pb-4">
                      Creating and integrating Application Programming
                      Interfaces (APIs) that facilitate communication between
                      different software systems. This enhances functionality
                      and interoperability, allowing businesses to connect
                      existing systems with new applications seamlessly.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Cloud Solutions</h5>
                    <p className="mb-0">
                      Developing cloud-based applications that enable businesses
                      to access their software and data from anywhere. This
                      enhances collaboration and scalability while optimizing
                      applications for performance and security in a cloud
                      environment.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSoundcloud />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
            <img
              className="width-full mt-5"
              src={softwareqaservices}
              alt="indcom"
            />
          </Col>
          <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
            <h2 className="mt-5 pt-4">
              Why Choose Us for Software & Web Development?
            </h2>
            <ul className="list-inline list-group ul-list-about mt-4">
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Expertise in diverse programming languages and frameworks</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Customized solutions tailored to your business needs</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Strong focus on user experience and interface design</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Rigorous testing and quality assurance processes</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Proven track record of successful project delivery</p>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Common>
  );
};

export default SoftwareAndWebDevelopment;
