import React from 'react';
import Common from "../../layouts/Common";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { FaFacebookF } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import avater from '../../assets/images/avater.svg';
const Team = () => {
    return (
        <Common>
        <section className="all-header"></section>
    
        <section className="all-company-feature bg-deep-gray bg-deep-gray2 mt-3 pt-3 bg-white certification-slide blog-wr">
        <Container>
          <Row>
    
            <Col md={12} className="mt-5 mb-4 text-center zindex-all">
        <h2 className="member-h2">MEET THE LEADERSHIP</h2>
        <h5 className="text-center mt-3 peopele-h5">The People That Propel Us Forward</h5>
        <p className="text-center mt-3">
        NexaLinx is led by a team of passionate, bold, and creative people. Meet our cross-cultural team of IT & Engineering professionals who ensure the success
         of your operations.<br/> Discover how our strong team of experienced and competent leaders can create a difference for your business.
        </p>
            </Col>
          </Row>
        </Container>
      </section>
    
      <section className="all-company-feature mtp mt-5">
        <Container>
      
          <Row className='mt-4 pt-3'>
            <Col md={3} data-aos="zoom-in">
                <div className="team-member-block">
                <img src={avater} alt="avater" className='client-av' />
                <h3>A B M Whaiduzzaman
</h3>
                <p className="text-center">
                    <strong>Chief executive officer
</strong>
                </p>


                <div className="team-social">
                <NavLink exact to="">
          <Button variant="btn">
          <FaFacebookF/>
          </Button>
        </NavLink>
        <NavLink exact to="">
          <Button variant="btn">
          <FaTwitter/>
          </Button>
        </NavLink>
      
          <Button variant="btn">
          <a href="https://www.linkedin.com/mwlite/company/nexalinx">
          <FaLinkedinIn/>
          </a>
          </Button>
                </div>
                </div>
            </Col>
    
    
            <Col md={3} data-aos="zoom-in">
                <div className="team-member-block">
                <img src={avater} alt="avater" className='client-av' />
                <h3>Fahim uddin
</h3>
                <p className="text-center">
                    <strong>Chief operating officer 
</strong>
                </p>


                <div className="team-social">
                <NavLink exact to="">
          <Button variant="btn">
          <FaFacebookF/>
          </Button>
        </NavLink>
        <NavLink exact to="">
          <Button variant="btn">
          <FaTwitter/>
          </Button>
        </NavLink>
      
          <Button variant="btn">
          <a href="https://www.linkedin.com/mwlite/company/nexalinx">
          <FaLinkedinIn/>
          </a>
          </Button>
                </div>
                </div>
            </Col>
    
            <Col md={3} data-aos="zoom-in">
                <div className="team-member-block">
                <img src={avater} alt="avater" className='client-av' />
                <h3>Yousuf Bhuiyan
</h3>
                <p className="text-center">
                    <strong>Chief Marketing Officer 
</strong>
                </p>


                <div className="team-social">
                <NavLink exact to="">
          <Button variant="btn">
          <FaFacebookF/>
          </Button>
        </NavLink>
        <NavLink exact to="">
          <Button variant="btn">
          <FaTwitter/>
          </Button>
        </NavLink>
      
          <Button variant="btn">
          <a href="https://www.linkedin.com/mwlite/company/nexalinx">
          <FaLinkedinIn/>
          </a>
          </Button>
                </div>
                </div>
            </Col>
            <Col md={3} data-aos="zoom-in">
                <div className="team-member-block">
                <img src={avater} alt="avater" className='client-av' />
                <h3>Nazmus sakib
</h3>
                <p className="text-center">
                    <strong>Director</strong>
                </p>


                <div className="team-social">
                <NavLink exact to="">
          <Button variant="btn">
          <FaFacebookF/>
          </Button>
        </NavLink>
        <NavLink exact to="">
          <Button variant="btn">
          <FaTwitter/>
          </Button>
        </NavLink>
      
          <Button variant="btn">
          <a href="https://www.linkedin.com/mwlite/company/nexalinx">
          <FaLinkedinIn/>
          </a>
          </Button>
                </div>
                </div>
            </Col>
    
         
        
          </Row>
        </Container>
      </section>
    </Common>
    );
};

export default Team;