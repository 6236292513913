import React from "react";

import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import {
  FaBrain,
  FaCheckCircle,
  FaHandPointRight,
  FaLaptop,
  FaLaptopCode,
  FaLockOpen,
} from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import { FaWaveSquare } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { FaSlideshare } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { FaCommentAlt } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FaArrowRight } from "react-icons/fa";

import { FaStarOfLife } from "react-icons/fa";
import { FaScribd } from "react-icons/fa";
import { FaFileCode } from "react-icons/fa";
import { FaSearchengin } from "react-icons/fa";
import { FaSolarPanel } from "react-icons/fa";
import { FaScrewdriver } from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";
import { RiRobot2Line } from "react-icons/ri";

import Container from "react-bootstrap/Container";
import worka from "../../assets/images/worka.png";
import workb from "../../assets/images/workb.png";
import workc from "../../assets/images/workc.png";
import workd from "../../assets/images/workd.png";
import worke from "../../assets/images/worke.png";
import workf from "../../assets/images/workf.png";
import indu1 from "../../assets/images/indu1.svg";
import indu2 from "../../assets/images/indu2.svg";
import indu3 from "../../assets/images/indu3.svg";
import indu4 from "../../assets/images/indu4.svg";
import indu5 from "../../assets/images/indu5.svg";
import indu6 from "../../assets/images/indu6.svg";
import softwareqaservices from "../../assets/images/software-qa-services.svg";
import serviceall from "../../assets/images/serviceall.svg";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ContactForm from "../../components/ContactForm";
const ArtificialIntelligence = () => {
  const options2 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>Artificial Intelligence</strong>
                </h1>
                <p className="text-white p20 mb-4">
                  Automation Services Limited (ASL) is at the forefront of the
                  artificial intelligence revolution, helping businesses harness
                  the power of AI to drive innovation. Our expert team develops
                  intelligent solutions that automate processes, enhance
                  decision-making, and improve customer experiences. From
                  machine learning algorithms to natural language processing, we
                  tailor AI applications to meet your specific needs. By
                  integrating AI into your operations, you can streamline
                  workflows, reduce costs, and gain valuable insights from data.
                  Partner with ASL to unlock the full potential of artificial
                  intelligence and stay ahead in the competitive landscape.
                </p>
              </div>
              <Row>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Transformative AI Solutions</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Data-Driven Insights</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Automation of Tasks</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Enhanced Customer Experiences</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Predictive Analytics</label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaHandPointRight />
                    </span>
                    <label>Cutting-Edge Technology</label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>

      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="mt-4 mb-4" data-aos="fade-left">
              <h2>Our Artificial Intelligence Services</h2>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaLaptopCode />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Machine Learning Development</h5>
                    <p className="mb-0">
                      Creating algorithms that enable systems to learn from data
                      and improve their performance over time without explicit
                      programming. This involves training models to recognize
                      patterns and make predictions, applicable in various
                      domains such as finance, healthcare, and e-commerce.
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Natural Language Processing (NLP)</h5>
                    <p className="mb-0">
                      Implementing NLP techniques to allow computers to
                      understand, interpret, and generate human language. This
                      includes applications like chatbots, sentiment analysis,
                      and language translation, enhancing human-computer
                      interaction and customer engagement.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaBrain />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className="img-width" src={serviceall} alt="serviceall" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaGlobe />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0"> Computer Vision</h5>
                    <p className="mb-0 pb-3">
                      Developing systems that enable computers to interpret and
                      process visual information from the world. This includes
                      image and video analysis, object recognition, and facial
                      recognition, which can be utilized in industries such as
                      security, retail, and autonomous vehicles.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Predictive Analytics</h5>
                    <p className="mb-0">
                      Using AI algorithms to analyze historical data and predict
                      future outcomes. This approach helps businesses make
                      data-driven decisions, optimize operations, and improve
                      customer experiences by anticipating needs and trends.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <TbReportAnalytics />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <RiRobot2Line />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Robotic Process Automation (RPA)</h5>
                    <p className="mb-0 pb-4">
                      Automating repetitive and rule-based tasks using AI
                      technologies to increase efficiency and reduce human
                      error. RPA can streamline workflows in various sectors,
                      allowing employees to focus on more strategic activities.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">AI Consulting and Strategy</h5>
                    <p className="mb-0">
                      Providing expert guidance on AI adoption and integration
                      into business processes. This includes assessing
                      organizational readiness, developing a tailored AI
                      strategy, and ensuring alignment with business goals to
                      maximize the value of AI initiatives.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaStarOfLife />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
            <img
              className="width-full mt-5"
              src={softwareqaservices}
              alt="indcom"
            />
          </Col>
          <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
            <h2 className="mt-5 pt-4">
              Why Choose Us for Artificial Intelligence?
            </h2>
            <ul className="list-inline list-group ul-list-about mt-4">
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Deep expertise in machine learning and NLP</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Tailored AI solutions to fit your business goals</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Proven methodologies for data-driven decision making</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Strong focus on ethical AI practices and governance</p>
              </li>
              <li>
                {" "}
                <span>
                  <FaCheckCircle />
                </span>
                <p>Ongoing support and optimization for AI systems</p>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Common>
  );
};

export default ArtificialIntelligence;
